import { getAssetURL } from "../utils/get-asset-url";
import React from 'react';

export default function Customer({customer}) {
    const imageClassName = customer.rounded === true?"rounded":"";

    return (
        <div className="customer bg-secondary-500" key={`customer#${customer.id}`}>
            <a href={customer.website} target="_blank" rel="noopener noreferrer">
                <img src={getAssetURL(customer.logo)} className={imageClassName} alt={`${customer.name} logo`}></img>
            <div className="description">
                {customer.description}
            </div>
            </a>
          </div>
    )
}