import Blog from "./components/blog_post";
import Project from "./components/project";
import Customer from "./components/customer";
import { getDirectusClient } from "./lib/directus";
import parseMarkdown from "./lib/parseMarkdown";
import React from 'react';
import { Async } from 'react-async'; // https://css-tricks.com/fetching-data-in-react-using-react-async/

const getIntro = async () => {
  const directus = await getDirectusClient();
  const content = await directus.items("aka_website").readOne(1);

  let blog = await directus.items("aka_blog").readByQuery({
    sort: ['-id'],
    limit: 5
  });
  blog = blog.data;
  return (<div id="intro">
    <div className="tile-grid">
      <article className="tile grid-col-span-2 flow bg-primary-400 quote text-neutral-100" key="selfIntroduction">
        <div className="cta">
          <img src="https://directus.akanixon.de/assets/72440ade-614f-4424-9bcd-34f8628c0ff5" alt="Himori"></img>
          <div>
            <p>Follow me on <a rel="noopener noreferrer" href="https://x.com/Himori_Tempest" target="_blank">X(Twitter)</a>
              <br></br>
              <br></br>
              Check out my projects on <a rel="noopener noreferrer" href="https://github.com/HimoriTempest">Github</a>
            </p>
          </div>
        </div>
      </article>

      <article className="tile flow bg-secondary-500 text-neutral-100" key="selfIntroduction2">
        <div className="flex">
          <div>
            <h2 className="name">{content.i_name}</h2>
            <p className="position">{content.i_description}</p>
          </div>
        </div>
        <p>
          {content.i_b_content}
        </p>
        <p dangerouslySetInnerHTML={{ __html: parseMarkdown(content.i_content) }}>
        </p>
      </article>

      <article className="tile grid-col-span-2 flow bg-secondary-500 text-neutral-100 enigma" key="advert1">
        <div className="flex">
          <img className="border-primary-400 avatar" src={`https://directus.akanixon.de/assets/${content.ad1_logo}`} alt="Profile"></img>
          <div className="tile-info">
            <h2 className="name">
              <a rel="noopener noreferrer" className="title" href={content.ad1_link} target="_blank">{content.ad1_name}</a>
            </h2>
            <p className="position">{content.ad1_description}</p>
          </div>
          <p></p>
        </div>
        <span className="content" dangerouslySetInnerHTML={{ __html: parseMarkdown(content.ad1_content) }}></span>
      </article>

      <div className="tile feed" id="blog" key="blog_section">
        <h2 className="blog_title" key="blog_title">
          <a rel="noopener noreferrer" className="title" href="https://autocode.com/Akanixon/threads/" target="_blank">Blog</a>
        </h2>
        {blog.map(blog_post => (<Blog blog_post={blog_post} />))}
      </div>
    </div>
  </div>);
};
const getSlogan = async () => {
  const directus = await getDirectusClient();
  let data = await directus.items("aka_website").readOne(1, { fields: ["slogans"] });
  const slogans = data.slogans.split("\n");
  const slogan = slogans.length ? `<${slogans[Math.floor(Math.random() * slogans.length)]}>` : "";
  return slogan;
};
const getCustomers = async () => {
  const directus = await getDirectusClient();
  let customers = await directus.items("aka_customers").readByQuery({ limit: -1 });
  customers = customers.data;
  return (<div id="customers-grid">
    <h2>Customers</h2>
    {customers.map(customer => (
      <Customer customer={customer} />
    ))}
  </div>);
};
const getProjects = async () => {
  const directus = await getDirectusClient();
  let projects = await directus.items("aka_projects").readByQuery({filter:{ status: "published" }, limit: -1 });
  projects = projects.data;
  let tags = await directus.items("aka_tags").readByQuery({ limit: -1 });
  tags = tags.data;
  return (<div id="projects">
    <h2>Projects</h2>
    {projects.map(project => (
      <Project
        project={project}
        tags={tags} />
    ))}
  </div>);
};

function Page() {
  return (
    <main>
      <title>Himori | Landing Page</title>
      <div id="sec_headline">
        <div className="headline">
          <a href="/">
            <h1>Himori</h1>
          </a>
          <Async promiseFn={getSlogan}>
            <Async.Loading>{() => { return (<p id="slogan">Loading...</p>) }}</Async.Loading>
            <Async.Fulfilled>{data => { return (<p id="slogan">{data}</p>) }}</Async.Fulfilled>
            <Async.Rejected>{error => { console.log(error); return (<p id="slogan">Something went wrong!</p>) }}</Async.Rejected>
          </Async>
          <a className="hidden_text" href="#intro"> move to tiles section
            <span className="arrow">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
        </div>
      </div>

      <div id="content" key="content">
        <Async promiseFn={getIntro}>
          <Async.Loading>{() => { return (<div id="intro" key="intro-section"></div>) }}</Async.Loading>
          <Async.Fulfilled>{data => { return data }}</Async.Fulfilled>
        </Async>

        <Async promiseFn={getProjects}>
          <Async.Loading>{() => { return (<div id="projects" key="project-section"></div>) }}</Async.Loading>
          <Async.Fulfilled>{data => { return data }}</Async.Fulfilled>
        </Async>

        <Async promiseFn={getCustomers}>
          <Async.Loading>{() => { return (<div id="customers-grid" key="customer-section"></div>) }}</Async.Loading>
          <Async.Fulfilled>{data => { return data }}</Async.Fulfilled>
        </Async>
      </div>
    </main>
  );
}

export default Page;
