import { getAssetURL } from "../utils/get-asset-url";
import parseMarkdown from "../lib/parseMarkdown";
import React from 'react';

export default function Project({project,tags}) {
    const image = () =>{
        if(project.image){
            return(<img className="project-img" src={getAssetURL(project.image)} alt="Logo of the project"></img>)}
            else{return null}
        }
    return (
        <div key={`project#${project.id}`} className="project bg-secondary-500">
            <div className="flex">
                <div className="project-image">
                    <img src={getAssetURL(project.logo)} alt="Logo of the project"></img>
                </div>
                <div className="project-info">
                    <h2 className="project-title"><a rel="noopener noreferrer" className="title" href={project.link?project.link:"/"} target="_blank">{project.name}</a></h2>
                    <p className="project-description">
                        {project.tags.map(e => {
                            let tagData = tags.find(t => t.name === e);
                            if(!tagData.link){
                                return (
                                    <a className="tag nolink" key={tagData.id}>{tagData.name}</a>
                                )
                            }else{
                                return (
                                    <a className="tag link" key={tagData.id} href={tagData.link}>{tagData.name}</a>
                                )
                            }
                            })
                        }
                    </p>
                </div>
            </div>
            <p className="project-text" dangerouslySetInnerHTML={{ __html: parseMarkdown(project.content) }}></p>
            <br></br>
            {image()}
            <a rel="noopener noreferrer" className="project-link" href={project.button_link} target="_blank">{project.button_text}</a>
        </div>
    )
}
