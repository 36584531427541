import parseMarkdown from "../lib/parseMarkdown";
import React from 'react';

export default function Blog_post({blog_post}) {
    const date_created = new Date(blog_post.date_created);
    
    const date = `${date_created.getDate()}/${+date_created.getMonth() + 1}/${date_created.getFullYear()}`;
    return (
        <div className="blog_post" key={`blog#${blog_post.id}`}>
            <a rel="noopener noreferrer"
                href={blog_post.external_link}
                target="_blank" aria-label={`Read more about "${blog_post.title}" here:`}>
                <span className="link"></span>
            </a>
            <p className="date">{date}</p>
            <p className="post_title"><strong>{blog_post.title}</strong></p>
            <span className="post_preview" dangerouslySetInnerHTML={{ __html: parseMarkdown(blog_post.preview) }}>
            </span>
        </div>
    );
}