import React from 'react';

const Page = () => {
    return (
    <main>
        <title>Akanixon | Landing Page</title>
        <div id="sec_headline">
            <div className="headline">
                <a href="/">
                    <h1>404</h1>
                </a>
                <p id="slogan">Page NOT found</p>
            </div>
        </div>
    </main>
    );
}

export default Page;